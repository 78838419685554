
#app fieldset legend {
  cursor: pointer;
  font-size: 1.2em;
  font-weight: bold;
}

#app .card {
  border: 2px solid black;
  border-radius: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 2em;
  height: calc(8vw + 300px);
  max-height: 425px;
  max-width: 300px;
  min-width: 200px;
  width: 20%;
}

#app .card.registered {
  background: rgba(0, 204, 0, 0.3);
}

#app .card.selected {
  background: rgba(128, 0, 128, 0.2);
}

#app .card h3 {
  font-size: 1.1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#app .card img {
  height: 32cqb;
  object-fit: contain;
  white-space: nowrap;
  width: 100%;
}